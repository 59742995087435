#main-nav {
    width: 100%;
    height: auto;
    background: linear-gradient(to right, #03228F, #0E73E4);
position: fixed;
top: 0;
left: 0;
z-index: 111;
box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

#llg {
    height: 70px;
    mix-blend-mode: lighten;
    margin-top: -7px;
}

nav,
nav a {
    text-decoration: none;
    color: white;
    font-family: var(--font-7);
    text-transform: uppercase;
}

nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10vw;
}

.nv-left {
    display: flex;
    gap: 30px;
    font-weight: 500;
    font-size: 1rem;
    flex: 1;
    justify-content: flex-end;
    margin-right: 75px;
}

.nv-right {
    display: flex;
    gap: 7px;
    font-size: 0.9rem;
}

.ham {
    display: none !important;
}
#web-c {
    width: 100vw;
    height: auto;
    position: relative;
}

.hidden-nav {
    height: 100dvh;
    width: 300px;
    background: #ffffffd8;
    backdrop-filter: blur(3px);
    display: flex;
    position: fixed;
    top: 0 !important;
    right: -800px;
    z-index: 111;
    flex-direction: column;
    padding: 25px;
    /* padding-top: 75px; */
    box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.249);
    gap: 25px;
    transition: right 0.3s ease;
    /* transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.1); */
    /* Smooth transition */
}

.hidden-nav.visible {
    right: 0;
    /* Move onscreen */
}

.hidden-nav>* {
    color: #252525 !important;
}

.hidden-nav .hid-link {
    display: flex;

    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    padding-left: 75px;
    width: 100%;
    /* text-align: left; */
    justify-content: flex-start;
    flex: 1;

}


.cls>* {
    width: fit-content !important;
}

.hid-ico a {
    color: #252525;
}

.hid-ico a {
    color: #252525;
}

.hidden-nav .hid-link a {
    color: #252525;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.2rem;
    display: block;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
}

.hidden-nav .hid-link a:hover {
    color: #0a194d;
}

.hid-link a:hover::after {
    width: 100%;

}

.hid-link a::after {
    content: '';
    background: #0E73E4;
    height: 3px;
    width: 0%;
    display: inline-block;
    /* margin-top: -25px !important; */
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 3px;
    transition: 0.3s ease-in-out;
    /* position: ; */
}

.hid-ico {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 12.5px;
}