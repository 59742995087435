@import url('https://fonts.googleapis.com/css2?family=Anton&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Marcellus&family=Michroma&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Prompt:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Russo+One&family=Saira:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
    scroll-behavior: smooth;
    }

    :root {
        --blue : #0E73E4;
        --blue-2:#03228F;
        --font-1: 'Anton',
            sans-serif;
        --font-2: 'Kanit',
            sans-serif;
        --font-3: 'Marcellus',
            serif;
        --font-4: 'Michroma',
            sans-serif;
        --font-5: 'Poppins',
            sans-serif;
        --font-6: 'Prompt',
            sans-serif;
        --font-7: 'Roboto',
            sans-serif;
        --font-8: 'Russo One',
            sans-serif;
        --font-9: 'Saira',
    sans-serif;
}

html,
body {
    height: auto;
    width: 100dvw;
    overflow-x: hidden;
    position: relative;
}