/* src/GridLayout.css */
.grid-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(6, 12.5vw) 25vw;
    grid-template-areas:
        "item1 item1 item1 item1 item2 item2 item3 item3"
        "item1 item1 item1 item1 item2 item2 item3 item3"
        "item4 item4 item5 item5 item5 item5 item3 item3"
        "item4 item4 item5 item5 item5 item5 item3 item3"
        "item6 item6 item6 item6 item6 item7 item7 item7"
        "item6 item6 item6 item6 item6 item7 item7 item7"
        "item8 item8 item8 item8 item8 item8 item8 item8";
        gap: 15px;
        padding: 15px;
}

.grid-item {
    position: relative;
    background-color: #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.297);
    }

    .grid-item::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
        z-index: 11;
        height: 100%;
        display: grid;
        place-items: center;
        width: 100%;
        transition: right 0.3s ease;
        transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.1);
    }

    .grid-item:hover::after {
        content: 'view more';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
        z-index: 11;
        height: 100%;
        display: grid;
        place-items: center;
        width: 100%;
        background: rgba(0, 0, 0, 0.679);
        backdrop-filter: blur(2px);
        color: white;
        font-family: var(--font-4);
        font-size: 2rem;
        font-weight: 500;
        text-transform: capitalize;
}

/* 
.grid-item:not(:hover)::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    z-index: 111;
    height: 100%;
    display: grid;
    place-items: center;
    width: 100%;
    background: rgba(27, 27, 27, 0.712);
    backdrop-filter: blur(3px);
} */

.grid-item::before {
    content: "";
    display: block;
    padding-bottom: 100%;
    /* Aspect ratio 1:1 */
}

.grid-item>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.item1 {
    grid-area: item1;
    background-color: #b3d9ff;
}

.item2 {
    grid-area: item2;
    background-color: #ffcccc;
}

.item3 {
    grid-area: item3;
    background-color: #dab3ff;
}

.item4 {
    grid-area: item4;
    background-color: #ffcccc;
}

.item5 {
    grid-area: item5;
    background-color: #ffffb3;
}

.item6 {
    grid-area: item6;
    background-color: #d9d9d9;
}

.item7 {
    grid-area: item7;
    background-color: #99ccff;
}

.item8 {
    grid-area: item8;
    background-color: #cce6ff;
}

.item9 {
    grid-area: item9;
    background-color: #b3d9ff;
}

@media (max-width: 768px) {
    .grid-container>* {
        height: auto;
        width: 100%;
        max-width: 400px;
        box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.129);
    }

    .grid-container {
        padding: 25px;
        gap: 25px;
        /* place-items: center; */
        /* grid-template-columns: repeat(9, 300px);
        grid-template-rows: 1fr;
        grid-template-areas:
            "item1"
            "item2"
            "item3"
            "item4"
            "item5"
            "item6"
            "item7"
            "item8"
            "item9"; */
        height: auto;
        width: 100%;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        flex-direction: column !important;
    }
}
